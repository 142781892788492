import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useTranslation } from '@wix/wix-i18n-config';

import uri from '@wix/communities-forum-client-commons/dist/src/services/urijs';

const NUMBER_REGEX = /(\d+(\.?,? ?\d*)*)/g; // the "space" symbol in this regex is special, don't modify

const showKeys =
  typeof window !== 'undefined' && uri(window.location).hasQuery('debug-translate', 'true');

export default function withTranslate(WrappedComponent) {
  const WithTranslate = props => {
    const [t, i18n] = useTranslation();
    const translations = i18n.store.data[i18n.language].translation;
    let theT;
    theT = (key, ...args) => {
      const value = translations[key];
      const translated = t(key, ...args);

      // transform numbers in plurals to compact ones, as ICU doesn't have this out of the box
      if (value && value.includes('plural') && value.includes('{count')) {
        const unformattedNumber = args[0].count;
        if (unformattedNumber) {
          return translated.replace(NUMBER_REGEX, t('number', { count: unformattedNumber }));
        }
      }

      return translated;
    };

    if (showKeys) {
      theT = key => key;
    }

    return <WrappedComponent {...props} language={i18n.language} t={theT} />;
  };

  hoistNonReactStatics(WithTranslate, WrappedComponent);

  return WithTranslate;
}
