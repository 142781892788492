import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import TimeAgo from '../time-ago';
import { BulletSeparator, A11yDashSeparator } from '../separator';
import styles from './quick-user-info.scss';
import { getBadgeById } from '../../selectors/badge-selectors';
import { connect } from '../../../common/components/runtime-context';
import { getIsBadgesEnabled } from '../../selectors/app-settings-selectors';
import { Badge } from '../../containers/badge-async';

function QuickUserInfo({
  date,
  showDate,
  showBadges,
  showFirstSeparator,
  membersBadge,
  isTinyBadge,
  isBadgesEnabled,
  noMargin,
  truncateBadgeTitle,
  className,
}) {
  const renderTime = () => (
    <div className={styles.time}>
      <TimeAgo time={date} className={styles.date} />
    </div>
  );

  const renderSeparators = () => (
    <div className={styles.separator}>
      <A11yDashSeparator />
      <BulletSeparator />
    </div>
  );

  const renderBadge = () => {
    if (membersBadge)
      return (
        <div className={styles.badgeContainer}>
          {showFirstSeparator && renderSeparators()}
          <div className={styles.badge}>
            <Badge
              badge={membersBadge}
              size={isTinyBadge ? 'tiny' : 'small'}
              truncateTitle={truncateBadgeTitle}
              hideBackground
            />
          </div>
          {showDate && renderSeparators()}
        </div>
      );
  };

  const badges = showBadges && isBadgesEnabled && renderBadge();
  const time = showDate && renderTime();

  return badges || time ? (
    <span
      className={classNames(styles.container, !noMargin && styles.extraMarginTop, className)}
      data-hook="quick-user-info"
    >
      {badges}
      {time}
    </span>
  ) : null;
}

QuickUserInfo.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string,
  date: PropTypes.string,
  t: PropTypes.func,
  showDate: PropTypes.bool,
  showBadges: PropTypes.bool,
  showFirstSeparator: PropTypes.bool,
  membersBadge: PropTypes.object,
  isTinyBadge: PropTypes.bool,
  isBadgesEnabled: PropTypes.bool,
  noMargin: PropTypes.bool,
  truncateBadgeTitle: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const isBadgesEnabled = getIsBadgesEnabled(state, host.style);
  return {
    isBadgesEnabled,
    membersBadge: isBadgesEnabled && getBadgeById(state, get(ownProps, 'user.badges[0]', '')),
  };
};

QuickUserInfo.defaultProps = {
  showBadges: true,
  isTinyBadge: true,
  truncateBadgeTitle: true,
};

export default flowRight(connect(mapRuntimeToProps))(QuickUserInfo);
